<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 顶部搜索区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-button v-if="!hasTaoteUI" type="primary" @click="addobj()">添加商品</el-button>
          <el-button v-if="!hasTaoteUI" type="primary" @click="syncHaoShiQiGoods()"
            :loading="addBtnLoading">同步好食期商品</el-button>
          <el-button v-if="hasTaoteUI" type="primary" @click="toggleTaoteDialog">添加淘特商品</el-button>
          <el-button v-if="hasTaoteUI && isAdmin" type="primary" @click="toggleTaoteAuthDialog">淘特授权</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-input v-model="pages.title" class="search-input" placeholder="商品名称" clearable />
          <el-input v-model="pages.goodsId" class="search-input" placeholder="商品ID" clearable />
          <el-input v-if="hasTaoteUI" v-model="pages.ttItemId" class="search-input" placeholder="淘特商品ID" clearable />
          <el-input v-model="pages.goodsCode" class="search-input" placeholder="商品编码" clearable />
          <el-input v-model="pages.remark" class="search-input" placeholder="商品描述" clearable />
          <el-select v-if="hasChannelUI" v-model="pages.channel" placeholder="渠道" class="search-select">
            <el-option v-for="item in channelList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
          <el-select v-model="pages.status" placeholder="商品状态" class="search-select">
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
          <el-select v-if="hasErpUI" v-model="pages.ifErp" placeholder="是否ERP" class="search-select">
            <el-option v-for="item in erpStatusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
          <el-select v-if="hasErpUI" v-model="pages.fromWhere" placeholder="商品来源" class="search-select">
            <el-option v-for="item in erpFromWhereList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
          <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 商品列表区域 -->
      <el-table :data="goodsList" border style="width: 100%">
        <el-table-column prop="sort" label="当页排序" width="80" />
        <el-table-column prop="title" label="商品名称" />
        <el-table-column prop="goodsId" label="商品ID" />
        <el-table-column v-if="hasTaoteUI" prop="ttItemId" label="淘特商品ID" />
        <el-table-column v-if="hasTaoteUI" prop="ttPrice" label="淘特售价（元）" />
        <el-table-column v-if="hasChannelUI" prop="channelName" label="渠道" />
        <el-table-column v-else prop="remark" label="商品描述" />
        <el-table-column prop="specialRate" label="商品特价" />
        <el-table-column label="成本价">
          <template slot-scope="scope">
            <span>{{ calcPrice(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存" />
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <img :src="transferMainPic(scope.row.mainPic)[0]" style="width: 60px; height: 60px; border: none" />
          </template>
        </el-table-column>
        <el-table-column label="商品状态">
          <template slot-scope="scope">
            {{ scope.row.status == '1' ? '上架中' : '未上架' }}
          </template>
        </el-table-column>
        <el-table-column label="是否上传ERP" v-if="hasErpUI">
          <template slot-scope="scope">
            {{ scope.row.ifErp == '1' ? '上传成功' : '未上传' }}
          </template>
        </el-table-column>
        <el-table-column label="商品来源" v-if="hasErpUI">
          <template slot-scope="scope">
            {{ scope.row.fromWhere == '1' ? '好食期' : '系统添加' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="addobj(scope.row)">编辑</el-button>
            <el-button type="danger" @click="removeGoodsById(scope.row.goodsId)">删除</el-button>
            <el-button v-if="scope.row.status == 0" type="success" @click="setStatus(scope.row.id, 1)">上架</el-button>
            <el-button v-if="scope.row.status == 1" type="warning" @click="setStatus(scope.row.id, 0)">下架</el-button>
            <el-button type="success" @click="copyGood(scope.row.id)">复制</el-button>
            <el-button v-if="scope.row.ifErp == '0' && hasErpUI" type="success"
              @click="GoodsUploadToErp(scope.row.id, 1)">上传到ERP</el-button>
            <el-button v-if="scope.row.ifErp == '1' && hasErpUI" type="success"
              @click="GoodsUploadToErp(scope.row.id, 0)">取消上传</el-button>
            <el-button type="primary" @click="showUpdateStock(scope.row.id, scope.row.stock)">修改库存</el-button>
            <el-button v-if="hasTaoteUI" type="warning" @click="openTaobao(scope.row.ttItemId)">查看淘宝链接</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="Pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pages.currentPage" :page-sizes="[1, 3, 10, 20]" :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="修改库存" :visible.sync="updateStockDialog" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-row>
          <el-input v-model="stockValue" placeholder="增加库存填正数，减少库存填负数" clearable />
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdateStockSubmit">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加淘特商品" :visible.sync="addTaoteDialog" width="80%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-row style="display: flex;">
          <el-input v-model="taoteId" placeholder="请输入淘特商品id" clearable />
          <el-button style="margin-left: 10px;" type="primary" @click="onSearchTaote">搜索</el-button>
        </el-row>
        <el-row>
          <el-table :data="taoteList" border style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="42" />
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <img v-if="scope.row.item.skuInfo" :src="scope.row.item.skuInfo.image"
                  style="width: 60px; height: 60px; border: none" />
                <img v-else-if="scope.row.item.mainPic" :src="scope.row.item.mainPic.split(',')[0]"
                  style="width: 60px; height: 60px; border: none" />
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称">
              <template slot-scope="scope">
                <span>{{ scope.row.item.title }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="SkuID" label="Sku">
              <template slot-scope="scope">
                <span>{{ scope.row.skuInfo ? scope.row.skuInfo.name : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quantityVague" label="库存">
              <template slot-scope="scope">
                <span>{{ scope.row.quantityVague }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="specialRate" label="淘特售价">
              <template slot-scope="scope">
                <span>￥{{ scope.row.finalPrice / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="seller" label="商家名称">
              <template slot-scope="scope">
                <span>{{ scope.row.seller.shopName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="SkuID" label="SkuID">
              <template slot-scope="scope">
                <span>{{ scope.row.skuId }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="goodsId" label="淘特商品ID">
              <template slot-scope="scope">
                <span>{{ scope.row.item.itemId }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="onAddTaote">添加</el-button>
        <el-button type="danger" @click="toggleTaoteDialog">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="淘特授权" :visible.sync="taoteAuthDialog" width="300px">
      <!-- 主要内容 -->
      <canvas id="qr-canvas" style="width: 200px;height: 200px;display: block;margin: 0 auto;" @click="generateQrCode" />
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode';
export default {
  data() {
    return {
      // 商品数据列表
      goodsList: [],
      taoteList: [],
      // 分页
      pages: {
        status: '',
        ifErp: '',
        title: '',
        goodsId: '',
        ttItemId: '',
        remark: '',
        channel: '',
        goodsCode: '',
        currentPage: 1,
        pageSize: 10,
        fromWhere: '',
      },

      total: 0,
      // 添加按钮加载状态
      addBtnLoading: false,

      // 商品Id
      goodsId: [],
      updateStockDialog: false,
      addTaoteDialog: false,
      taoteAuthDialog: false,
      currentId: '',
      stockValue: '',
      taoteId: '',
      currentStock: 0,
      hasChannelUI: false,
      hasErpUI: false,
      hasTaoteUI: false,
      channelList: [],
      statusList: [
        { key: '全部', value: null },
        { key: '上架中', value: '1' },
        { key: '未上架', value: '0' },
      ],
      erpStatusList: [
        { key: '全部', value: null },
        { key: '已上传', value: '1' },
        { key: '未上传', value: '0' },
      ],
      erpFromWhereList: [
        { key: '系统添加', value: '0' },
        { key: '好食期', value: '1' },
      ],
      multipleSelection: [],
      isAdmin: false,
    }
  },
  created() {
    // 商品列表
    this.getGoods()
    this.hasChannelUI = window.hasChannelUI();
    this.hasErpUI = window.hasErpUI();
    this.hasTaoteUI = window.hasTaoteUI();
    this.isAdmin = window.isAdmin();
    if (this.hasChannelUI) {
      this.$api.GetGoodsChannelList({ currentPage: 1, pageSize: 1000 }).then((res) => {
        const list = [{ key: '全部', value: null }]
        res.data.records.map(o => {
          list.push({ key: o.name, value: o.id })
        })
        this.channelList = list;
      })
    }
  },
  methods: {
    openTaobao(ttItemId) {
      window.open(`https://item.taobao.com/item.htm?id=${ttItemId}`)
    },
    calcPrice(data) {
      let res = '--'
      if (data.purchaseMoneyList && data.purchaseMoneyList.length > 0) {
        let curDate = new Date();
        data.purchaseMoneyList.map(o => {
          if (curDate > new Date(o.beginDate + ' 00:00:00') && curDate < new Date(o.endDate + ' 23:59:59')) {
            res = o.money
          }
        })
      }
      return res;
    },
    search() {
      this.pages.currentPage = 1;
      this.getGoods();
    },
    //  获取商品列表信息
    getGoods() {
      this.$api.GetGoodsListToPage(this.pages).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求商品失败')
        }
        this.goodsList = res.data.records
        this.total = res.data.total
      })
    },
    copyGood(id) {

      this.$api.GoodsClone({ id }).then((res) => {
        if (res.code !== 200) return this.$message.error('复制失败：' + res.message)
        this.$message.success('复制成功')
        this.getGoods();
      })
    },
    // 监听页码大小 也就是下拉框
    handleSizeChange(newSize) {
      this.pages.pageSize = newSize
      // 刷新列表
      this.getGoods()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange(newPage) {
      this.pages.currentPage = newPage
      // 刷新列表
      this.getGoods()
    },
    toggleTaoteDialog() {
      this.addTaoteDialog = !this.addTaoteDialog
    },
    toggleTaoteAuthDialog() {
      this.taoteAuthDialog = !this.taoteAuthDialog
      if (this.taoteAuthDialog) {
        this.generateQrCode()
      }
    },
    // 点击跳转到添加商品列表
    addobj(data) {
      let p = '';
      if (data) {
        p = '?id=' + data.id;
        var info = this.goodsList.find(o => o.id == data.id);
        localStorage.setItem('goods-' + data.id, JSON.stringify(info));
      }
      window.open('#/addGoods' + p)
      // this.$router.push('/addGoods' + p)
    },
    // 同步好食期商品
    syncHaoShiQiGoods(data) {
      this.addBtnLoading = true;
      this.$api.SyncHaoShiQiGoods(this.pages).then((res) => {
        if (res.code !== 200) {
          this.addBtnLoading = false;
          return this.$message.error('同步商品失败')
        }
        this.$message.success('同步成功')
        this.addBtnLoading = false;
        this.pages.currentPage = 1
        this.pages.pageSize = 10
        this.getGoods();
      })
    },
    showUpdateStock(id, stock) {
      this.updateStockDialog = true;
      this.currentId = id;
      this.currentStock = stock;
    },
    setStatus(id, status) {
      var type = status == 1 ? '上架' : '下架';
      this.$confirm('确定' + type + '商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        callback: action => {
          if (action === 'confirm') {
            this.$api.UpdateGoods2({ id, status }).then((res) => {
              if (res.code !== 200) {
                return this.$message.error(type + '商品失败')
              } else {
                this.$message.success(type + '商品成功')
              }
              this.getGoods()
            })

          }
        }
      })
    },
    onUpdateStockSubmit() {
      if (isNaN(this.stockValue)) return this.$message.error('库存只能输入数字')
      if ((+this.currentStock + +this.stockValue < 0) || (this.currentStock == '--' && this.stockValue < 0)) {
        return this.$message.error('减少值已超过当前库存值')
      }
      this.$api.UpdateGoods2({ id: this.currentId, stock: this.stockValue }).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('修改库存失败')
        } else {
          this.$message.success('修改库存成功')
          this.updateStockDialog = false;
        }
        this.getGoods()
      })
    },

    // 删除商品
    async removeGoodsById(goodsId) {
      console.log(goodsId)
      this.goodsId.push(goodsId)
      // 去重
      this.goodsId = [...new Set(this.goodsId)]
      console.log(this.goodsId)
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      // console.log(confirmResult)
      // 如果用户确认删除就返回个 confirm
      // 不删除就返回cancel
      if (confirmResult !== 'confirm') {
        // 当页面关闭或者取消的时候清空数组
        this.goodsId.splice(0, this.goodsId.length)
        // console.log(this.ID)
        return this.$message.info('取消了删除')
      }
      // 下面发起真正的删除请求
      this.$api.BatchToDeleteGoods(this.goodsId).then((res) => {
        if (res.code !== 200) {
          this.$message.error('删除商品失败')
        } else {
          this.$message.success('删除商品成功')
        }
        // 刷新列表
        this.getGoods()
      })
    },

    GoodsUploadToErp(id, ifErp) {
      if (ifErp) {
        this.$api.GoodsUploadToErp({ id, ifErp }).then((res) => {
          if (res.code !== 200) return this.$message.error('上传失败：' + res.message)
          this.$message.success('上传成功')
          this.getGoods()
        })
      } else {
        this.$api.UpdateGoods2({ id, ifErp }).then((res) => {
          if (res.code !== 200) return this.$message.error('取消上传失败：' + res.message)
          this.$message.success('取消上传成功')
          this.getGoods()
        })
      }

    },
    onSearchTaote() {
      //691688035099
      //651913088978
      this.$api.TTItemDetail({ itemId: this.taoteId }).then((res) => {
        console.log(res.data)
        let list = []
        const skuObj = JSON.parse(res.data.skuCore.sku2info)
        Object.keys(skuObj).forEach(skuId => {
          if (skuId != 0) {
            const target = res.data.skuBase.skus.find(o => o.skuId == skuId)
            const propPaths = target.propPath.split(';');
            const skuInfo = { name: '' };
            propPaths.forEach(pp => {
              const [pid, vid] = pp.split(':');
              const skuKeyInfo = res.data.skuBase.props.find(o => o.pid == pid);
              const _skuInfo = skuKeyInfo.values.find(o => o.vid == vid);
              skuInfo.name = skuInfo.name + ' ' + _skuInfo.name
              if (_skuInfo.image) {
                skuInfo.image = _skuInfo.image
              }
            })

            list.push({
              ...res.data,
              skuId,
              finalPrice: skuObj[skuId].finalPrice,
              quantityVague: skuObj[skuId].quantityVague,
              skuInfo
            })
          } else {
            list.push({
              ...res.data,
              skuId,
              finalPrice: skuObj[skuId].finalPrice,
              quantityVague: skuObj[skuId].quantityVague,
              skuInfo: {
                name: res.data.item.title,
                image: res.data.item.mainPic,
              }
            })
          }

        })
        if (list.length > 1) {
          list = list.filter(o => o.skuId != 0)
        }
        this.taoteList = list
      })


    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onAddTaote() {
      if (!this.multipleSelection.length) return this.$message.error('请至少选择一个商品')
      const skuIds = this.multipleSelection.map(o => o.skuId)
      const params = {
        itemId: this.multipleSelection[0].item.itemId,
        skuIds
      }
      this.$api.TTItemAdd(params).then((res) => {
        if (res.code !== 200) return this.$message.error('添加商品失败：' + res.message)

        this.$message.success('添加商品成功')
        this.getGoods();
        this.toggleTaoteDialog();
      })
    },
    generateQrCode() {

      this.$api.generateQrCode().then((res) => {
        QRCode.toCanvas(document.getElementById('qr-canvas'), res.data.qrCodeUrl, { width: 200 }, function (error) {
          if (error) console.error(error);
        });
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.search-input {
  width: 150px;
  margin-right: 5px;
}

.search-select {
  width: 140px;
  margin-right: 5px;
}

.search-date {
  width: 135px;
}
</style>
